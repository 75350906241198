<template>
  <nav class="d-flex justify-space-between align-center bg-transparent text-white" style="z-index: 25;">
    <img class="sd-logo" :src="require('@/assets/sd-logo-landscape.png')" alt="skate dogtor logo">
    <div id="nav-links" class="text-body-2 text-md-h6">
<!--      <router-link to="#">Home</router-link>-->
<!--      <router-link to="#about">About</router-link>-->
<!--      <router-link to="#about">About</router-link>-->
<!--      <router-link to="#about">About</router-link>-->
    </div>
  </nav>

</template>

<style scoped>
nav {
  position: -webkit-sticky; /* for browser compatibility */
  position: sticky;
  top: 0;
  padding: 8px 30px;
}
.sd-logo {
  height: 82px;
  width: auto;
}
a {
  margin-left: 12px;
  font-weight: bold;
  /* color: #2c3e50; */
  color: #42b983;
  text-decoration: none;
}

/* nav a.router-link-exact-active {
  color: #42b983;
  text-decoration: underline;
} */
</style>
<script setup lang="ts">
</script>
