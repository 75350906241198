<script>
</script>

<template>
<div class="home-about bg-grey-lighten-1 px-8 py-16">
  <v-row>
    <v-col cols="12" md="6">
      <div class="py-4 px-8">
        <img :src="require('@/assets/max-poodle.jpg')" alt="">
      </div>
    </v-col>
    <v-col cols="12" md="6">
      <div class="py-4 px-8">
        <p class="text-grey-darken-3 mb-5">At Skate Dogtor Pet Care we strive to ensure your dog is getting THE best exercise, while you’re living your busy life.. We provide energy releasing runs for active dogs and relaxing walks for less upbeat dogs. We will provide your dog with all of the physical activity needed and customize our visits to their needs and exercise levels. We also offer IN-HOME overnight pet sitting.</p>
        <p class="text-grey-darken-3 mb-5">My name is Max and I’m originally from Southern California. Growing up playing roller hockey as kid, led me to accomplishments I only dreamed of back then. Playing Jr hockey in Chicago, getting a scholarship to play at Boston University – where I met my beautiful wife and being drafted to the NHL’s Detroit Red Wings.</p>
        <p class="text-grey-darken-3 mb-5">Years later, in 2017 I finished my 5th professional hockey season playing for your cities, South Carolina Stingrays. I truly miss the game I was born to play, and the unbreakable bonds I made along the way – but I’m not out of my SKATES quite yet!</p>
        <p class="text-grey-darken-3 mb-5">Being an absolute dog enthusiast since I can remember, I combined my love for dogs and passion for hockey into the first of it’s kind, Dog Rollerblading Company – Skate Dogtor.</p>
        <p class="text-grey-darken-3 mb-5">Tailored exercises which allows your dog to run freely, harnessed and under my control. With my devotion and understanding of dogs, along side my skating ability and awareness, I provide THE BEST option for your dogs overall quality of life.</p>
        <p>Book a Skate!</p>
      </div>
    </v-col>
  </v-row>
</div>
</template>

<style scoped>
.home-about {
  img {
    width: 100%;
    height: auto;
  }
}
</style>
