<script>

</script>

<template>
<div class="home-banner bg-grey-darken-4 text-white">
  <div class="d-flex flex-column justify-center align-center py-16">
    <h2 class="font-fredericka text-h4 pb-6 px-4 text-center mt-4">The Best Dog Exercise Company in the Low Country!</h2>
  </div>
</div>
</template>

<style scoped>
.home-banner {
  //position: relative;
  //top: -99px;
  //left: 0;
}
</style>
