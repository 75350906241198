<script>
</script>

<template>
  <div class="home-contact px-8 py-16">
    <div class="pb-16">
    <div class="pb-16">
    <div class="pb-16">

      <v-row>
        <v-col cols="12" md="6">
          <div class="py-4 px-8">
            <p class="font-fredericka text-h4 text-grey-darken-3 mb-4">Contact the Skate Dogtor</p>
            <p class="text-grey-darken-3">We would love to hear from you.<br />
              Send us a message today!</p>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="py-4 px-8 pt-16">
            <p class="text-grey-darken-3">max @ skatedogtor . com</p>
          </div>
        </v-col>
      </v-row>

    </div>
    </div>
    </div>
  </div>
</template>

<style scoped>
.home-contact {

}
</style>
