<template>
 <v-container fluid class="bg-grey-darken-4 text-white">
  <v-row>
    <v-col>
      <p class="text-center text-grey-lighten-1">&#169; 2025 Skate Dogtor</p>
    </v-col>
<!--   <v-col cols="6">-->
<!--    <p class="text-h6">Group 1</p>-->
<!--    <ul>-->
<!--     <li><router-link to="#">Link</router-link></li>-->
<!--     <li><router-link to="#">Link</router-link></li>-->
<!--     <li><router-link to="#">Link</router-link></li>-->
<!--    </ul>-->
<!--   </v-col>-->
<!--   <v-col cols="6">-->
<!--    <p class="text-h6">Group 2</p>-->
<!--    <ul>-->
<!--     <li><router-link to="#">Link</router-link></li>-->
<!--     <li><router-link to="#">Link</router-link></li>-->
<!--     <li><router-link to="#">Link</router-link></li>-->
<!--     <li><router-link to="#">Link</router-link></li>-->
<!--     <li><router-link to="#">Link</router-link></li>-->
<!--    </ul>-->
<!--   </v-col>-->
  </v-row>
 </v-container>
</template>

<style scoped>
ul {
 list-style-type: none;
}
a {
 color: white;
 text-decoration: none;
}
a:hover {
 font-weight: bold;
 text-decoration: underline;
}
</style>
<script setup lang="ts">
</script>
