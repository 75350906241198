<template>
  <v-container fluid id="home-view" class="pa-0">

    <HomeHero />
    <HomeBannerOne />
    <HomeBenefits />
    <HomeBannerTwo />
    <HomeAbout />
    <HomeContact />

    

<!--    <HomeAbout />-->
<!--    <HomeServices />-->
<!--    <HomeFAQ />-->
<!--    <HomeMeetTeam />-->
<!--    <HomeFriends />-->
<!--    <HomeBookRun />-->
  </v-container>





  <!-- ====================================== -->
  <!-- ====================================== -->
  <!-- ====================================== -->
  <!-- https://runbuddymobile.com/ -->
  <!-- jeffsum.com -->
  
  <!-- <script src="vue-youtube/dist/vue-youtube.js"></script> -->
  <!-- <div>YouTube bg-video</div> -->
  <!-- <div :style="{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1}">
    <youtube :video-id="videoId" :autoplay="true" :width="width" :height="height" :player-vars="playerVars"></youtube>
  </div> -->

</template>

<script>
// @ is an alias to /src
import HomeHero from '@/pages/homepage/old/HomeHero.vue';
import HomeBannerOne from '@/pages/homepage/HomeBannerOne.vue';
import HomeBenefits from '@/pages/homepage/HomeBenefits.vue';
import HomeBannerTwo from '@/pages/homepage/HomeBannerTwo.vue';
import HomeAbout from '@/pages/homepage/HomeAbout.vue';
import HomeContact from '@/pages/homepage/HomeContact.vue';


export default {
  name: 'HomeView',
  components: {
    HomeHero,
    HomeBannerOne,
    HomeBenefits,
    HomeBannerTwo,
    HomeAbout,
    HomeContact,
  },
  data() {
    return {
      videoId: 'YOUR_YOUTUBE_VIDEO_ID',
      width: '100%', // Adjust width as needed
      height: '100%', // Adjust height as needed
      playerVars: {
        autoplay: 1, // Autoplay the video
        controls: 0, // Hide player controls
        disablekb: 1, // Disable keyboard controls
        loop: 1, // Loop the video
        modestbranding: 1, // Hide YouTube logo
        playsinline: 1, // Play inline on mobile devices
        mute: 1, // Mute the video
      },
    };
  },
}
</script>
