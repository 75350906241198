<script>
</script>

<template>
 <div class="home-hero d-flex flex-column text-center justify-space-around">
<!--   <img :src="require('@/assets/video-bg-2022-05-07.jpg')" alt="">-->
<!--   <img :src="require('@/assets/s1.jpg')" alt="">-->
<!--  <div class="flex-grow">&nbsp;</div>-->
<!--  <h1 class="text-h4 font-weight-bold">placeholder for an auto-playing background-video</h1>-->
<!--  <div class="flex-grow">&nbsp;</div>-->
<!--  <p class="pb-3 just-me-again-down-here-regular text-h6 text-uppercase">Say Something Else</p>-->
  </div>
</template>

<style scoped>
.home-hero {
  height: 85vh;
  position: relative;
  top: -99px;
  left: 0;
  margin-bottom: -99px;
  background-image: url('@/assets/s1.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  img {
    width: 100%;
  }
}
</style>
