<template>
  <HeaderNav/>
  <router-view/>
  <FooterNav/>
</template>


<script>
import HeaderNav from '@/components/HeaderNav.vue';
import FooterNav from '@/components/FooterNav.vue';
export default {
  components: {
    HeaderNav,
    FooterNav
  },
};
</script>


<style>
#app {
  font-family: Raleway, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  /* .just-me-again-down-here-regular {
    font-family: "Just Me Again Down Here", cursive;
    font-weight: 400;
    font-style: normal;
  } */

  .font-fredericka {
    font-family: 'Fredericka the Great', cursive;
  }
  .font-playfair {
    font-family: 'Playfair Display', serif;
  }

}
</style>
