<script>
</script>

<template>
<div class="home-benefits bg-grey-lighten-1 px-8 py-16">
  <v-row>
    <v-col cols="12" md="6">
      <div class="py-4 px-8">
        <p class="text-h5 font-playfair text-grey-darken-4 mb-4">Canine health professionals know how important regular exercise is for your dog’s physical and mental health, that’s why many clients have been referred to us by vets and trainers.</p>
        <p class="text-grey-darken-3">With all that being said, no two dogs are the same and I can’t wait to customize the most beneficial regiment for your dog! The health and safety of your dog is my primary concern on every skate and I’ll always keep an eye out for any signs of discomfort or fatigue. Big thanks to Non-Stop Dog Wear for sponsoring your dogs with THE best harnesses and leashes to ensure their safety.</p>
      </div>
    </v-col>
    <v-col cols="12" md="6">
      <div class="py-4 px-8 d-flex flex-column align-center">
        <p class="text-h5 font-playfair text-grey-darken-4 mb-4">How Your Dog Will Benefit</p>
        <ul class="benefits-list text-grey-darken-3">
          <li>strengthen muscles and bones</li>
          <li>rehabilitation energy outlet</li>
          <li>decrease separation anxiety</li>
          <li>increase hunger</li>
          <li>boost cardiovascular health</li>
          <li>calmer aggression</li>
          <li>less aggressive temperament</li>
          <li>help eliminate destructive behavior</li>
          <li>longevity</li>
          <li>promotes a hard/healthy SNOOZE!</li>
          <li>weight loss</li>
        </ul>
      </div>
    </v-col>
  </v-row>
</div>
</template>

<style scoped>
.home-benefits {
  .benefits-list {
    list-style: none; /* remove default bullets */
    padding: 0;

    li {
      padding-left: 40px; /* adjust spacing for custom icon */
      position: relative;
      margin-bottom: 8px;
    }

    li::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 18px; /* adjust size */
      height: 18px; /* adjust size */
      background-image: url('@/assets/dog-bone.svg');
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
</style>
